import React from "react";
import Layout from "../../components/Layout";

export default () => (
    <Layout>
        <section className="section">
            <div className="container">
                <div className="content">
                    <h1>Thank you for your enquiry!</h1>
                    <p>
                        We will look at the details of your enquiry as soon as
                        possible. In the meantime, please watch out for us
                        responding in your emails as we will ask you to send over
                        any reference images you have.
                    </p>
                </div>
            </div>
        </section>
    </Layout>
);
